.navigationBg{
    width: 100%;
    height: 90px;
    background-color: var(--white);
}

.logo{
    height: 90px;
    display: flex;
    align-items: center;
}
.logo img{
    max-width: 180px;
}
.headerContactList ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.headerContactList ul li{
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.headerContactList ul li a{
    color: #00AEEF;
    font-family: semibold;
}
.headerContactList ul li img{
    margin-right: 10px;
}

.headerButton{
    height: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.headerButton button{
    padding: 10px 25px;
    background-color: var(--secondary);
    border: none;
    outline: none;
    color: var(--white);
    font-size: 16px;
    border-radius: 10px;
    font-family: semibold;
    text-transform: capitalize;
}
.bottomNavigation{
    background-color: var(--primary);
    padding: 10px;
    transition: 0.5s ease-in-out;
}
.navigatonMenuList {
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigatonMenuList ul{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.navigatonMenuList ul li a{
    padding: 10px 30px;
    color: var(--white);
    font-family: semibold;
    text-transform: uppercase;
    font-size: 15px;
}
.stickyNav{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    transition: 0.5s ease-in-out;
}


@media (min-width: 319.98px) and (max-width: 379.98px) {
    .navigationBg{
        display: none;
    }
    .bottomNavigation{
        display: none;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .navigationBg{
        display: none;
    }
    .bottomNavigation{
        display: none;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .navigationBg{
        display: none;
    }
    .bottomNavigation{
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .navigationBg{
        display: none;
    }
    .bottomNavigation{
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {
    .navigationBg{
        display: none;
    }
    .bottomNavigation{
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 1024px) and (max-width: 1200px) {}

@media (min-width: 1200.98px) and (max-width: 1359px) {}

@media (min-width: 1359.98px) and (max-width: 1440px) {}