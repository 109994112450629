.offerHeaderImg img {
    filter: drop-shadow(10px 10px 10px #0000005f);
}

.offerHeaderImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.offerHeaderImg h2 {
    font-size: 30px;
    font-family: semibold;
    margin-top: 2rem;
}

.healthSafetyBodyContent h2 {
    text-align: center;
    font-size: 50px;
    font-family: title;
    text-transform: uppercase;
    margin-top: 2rem;
    color: var(--secondary);
    margin-bottom: 2rem;
}

.healthSafetyBodyContent p {
    font-size: 23px;
    letter-spacing: 0.09em;
    text-transform: capitalize;
    color: #6A6A6A;
    text-align: justify;
    margin: 1rem 0 3rem 0;
}

.healthSafetyBodyBg {
    position: relative;
}

.healthSafetyBodyBg::after {
    position: absolute;
    content: '';
    width: 493px;
    height: 493px;
    background-color: #FFA4CA;
    border-radius: 50%;
    bottom: 2rem;
    right: -10rem;
    z-index: -1;
}

.healthSafetyBodyBg::before {
    position: absolute;
    content: '';
    width: 493px;
    height: 493px;
    background: rgba(0, 174, 239, 0.35);
    border-radius: 50%;
    bottom: -10rem;
    left: -10rem;
    z-index: -1;
    opacity: 0.5;
}

@media (min-width: 319.98px) and (max-width: 379.98px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 1200.98px) and (max-width: 1359px) {
    .offerHeaderImg{
        margin-bottom: 2rem;
    }
    .offerHeaderImg h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent h2{
        font-size: 24px;
    }
    .healthSafetyBodyContent p{
        font-size: 16px;
    }
    .healthSafetyBodyBg::after,
    .healthSafetyBodyBg::before{
        display: none;
    }
}

@media (min-width: 1359.98px) and (max-width: 1440px) {}