.contactWays {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px dashed var(--secondary);
    width: 100%;
    height: 250px;
    border-radius: 20px;
    text-align: center;
    margin-top: 5rem;
    padding: 2rem;
}

.contactWaysIcon img {
    max-width: 40px;
    margin-bottom: 1.5rem;
}

.contactWaysContent h4 {
    font-family: boldfont;
}

.contactWaysContent p {
    color: #5E5E5E;
    margin-top: 1rem;
}

.contactWaysContent p a {
    color: #5E5E5E;
}

.contactFormBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.contactFormBg {
    padding: 5rem 0;
}

.contactFormBox form {
    width: 75%;
    height: 700px;
    background-color: #9CFFA0;
    border-radius: 82px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 12rem 0 4rem;
}

.contactFormTitle {
    position: relative;
    width: max-content;
}

.contactFormTitleName {
    z-index: 99;
    position: relative;
}

.contactFormTitleName h2 {
    width: max-content;
    height: 100%;
    color: var(--primary);
    font-size: 40px;
    font-family: extrabold;

}

.contactFormTitleBefore {
    position: relative;
}

.contactFormTitleBefore::before {
    position: absolute;
    content: '';
    width: 115%;
    background-color: var(--white);
    height: 20px;
    bottom: 10px;
}

.contactFormBox form input,
.contactFormBox form textarea {
    background-color: var(--white);
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 15px 20px !important;
    margin-top: 10px;
}

.contactFormBox form textarea {
    height: 166px !important;
}

.contactFormBox form label {
    padding: 15px 20px !important;
}

.contactMap {
    position: absolute;
    right: 0;
}

.contactMap iframe {
    width: 447px;
    height: 496px;
    border-radius: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.formBtn {
    background: #4AA74D;
    border-radius: 50px;
    width: max-content;
    padding: 6px;
    margin-top: 1.5rem;
}

.formBtn button {
    background-color: transparent;
    outline: none;
    border: 2px dashed var(--white);
    border-radius: 50px;
    font-size: 20px;
    font-family: boldfont;
    color: var(--white);
    padding: 10px 30px;
}

@media (min-width: 319.98px) and (max-width: 379.98px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .contactFormBox form{
        width: 100%;
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        border-radius: 30px;
    }
    .contactFormBox{
        display: initial;
    }
    .contactMap{
        position: inherit;
        margin-top: 2rem;
    }
    .contactMap iframe {
        width: 100%;
    }
    .formBtn button{
        font-size: 16px;
    }
}

@media (min-width: 1200.98px) and (max-width: 1359px) {}

@media (min-width: 1359.98px) and (max-width: 1440px) {}