.navigationSlider{
    z-index: 99999;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    background: var(--white);
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
    display: none;
}
.navigationSlider .mobileLogo{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
.navigationSlider .mobileLogo img{
    max-width: 120px;
}


.menubar{
    height: 60px;
    padding-right: 10px;
}
.menu_click {
    margin-top: 8px;
}


.menu_click div {
    width: 35px;
    height: 2px;
    margin-bottom: 8px;
    background-color: #000;
    transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91), opacity 500ms, background-color 250ms;
}


.menu_click.active #top {
    transform: translateY(10px) rotate(-135deg);
}

.menu_click.active #middle {
    opacity: 0;
    transform: rotate(135deg);
}

.menu_click.active #bottom {
    transform: translateY(-10px) rotate(-45deg);
}
.mobile-menu {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*------------animated menu bar navigation----------*/

.mobileSliderContent{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	overflow: scroll;
    overflow-x: hidden;
    background-color: var(--primary);
	z-index: 9999;
    transition: 0.5s ease-in-out; 
    transition-delay: 0.2s;
    display: flex;
    justify-content: center;
}
.mobileSliderContent span{
	position: absolute;
	top: 0;
	left: 0;
	width: 200%;
	height: 100%;
	display: block;
	transform-origin: bottom left;
	transition: 0.5s ease-in-out; 
	transform: rotate(-90deg);
}
.mobileSliderContent span:nth-child(1){
	background: var(--tertiary);
	transform: rotate(-90deg);
	transition-delay: 0.2s;
}
.mobileSliderContent span:nth-child(2){
	background: var(--secondary);
	transform: rotate(-90deg);
	transition-delay: 0.1s;
}
.mobileSliderContent span:nth-child(3){
	background: var(--primary);
	transform: rotate(-90deg);
	transition-delay: 0s;
}

.mobileSliderBox.active .mobileSliderContent{
    transition-delay: 0.2s;
    height: 100vh;
}

.mobileSliderBox.active .mobileSliderContent span{
	transform: rotate(0deg);
}
.mobileSliderBox.active .mobileSliderContent span:nth-child(1){
	z-index: 1;
	transition-delay: 0s;
}
.mobileSliderBox.active .mobileSliderContent span:nth-child(2){
	z-index: 2;
	transition-delay: 0.1s;
}
.mobileSliderBox.active .mobileSliderContent span:nth-child(3){
	z-index: 3;
	transition-delay: 0.2s;
}

.mobileSliderContentList{
	position: absolute;
	z-index: 100;
	transition: 0.5s;
	opacity: 0;
	visibility: hidden;
	transition-delay: 0s;
	list-style-type: none;
    margin-top: 6.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2.5rem;
    text-align: center;
}
.mobileSliderBox.active .mobileSliderContentList{
	opacity: 1;
	visibility: visible;
	transition-delay: 0.7s;
    
}
.mobileSliderContentList ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
}
.mobileSliderContentList ul li{
	border: 8px solid var(--white);
    box-shadow: rgb(0 0 0 / 9%) 0px 3px 12px;
    padding: 13px 0;
    border-radius: 50px;
    width: 100%;
    text-align: center;
    color: var(--white);
    text-transform: uppercase;
    font-size: 14px;
    font-family: boldfont;
    margin-bottom: 1.5rem;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.mobileSliderContentList ul a:nth-child(1) li{
    background-color: var(--secondary);
}
.mobileSliderContentList ul a:nth-child(2) li{
    background-color: var(--tertiary);
}
.mobileSliderContentList ul a:nth-child(3) li{
    background-color: var(--secondary);
}
.mobileSliderContentList ul a:nth-child(4) li{
    background-color: var(--tertiary);
}
.mobileSliderContentList ul a:nth-child(5) li{
    background-color: var(--secondary);
}
.mobileSliderContentList ul a:nth-child(6) li{
    background-color: var(--tertiary);
}
.mobileSliderContentList ul a:nth-child(7) li{
    background-color: var(--secondary);
}

.mobileSliderContentList h4{
    color: var(--white);
    font-family: semibold;
}
.mobileSliderContentList p{
    color: var(--white);
}
.mobileSliderContentList p a{
    color: var(--white);
}

/*-------------animated menu bar navigation end------------*/



@media (min-width: 319.98px) and (max-width: 379.98px) {
    .navigationSlider{
        display: block;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .navigationSlider{
        display: block;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .navigationSlider{
        display: block;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .navigationSlider{
        display: block;
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {
    .navigationSlider{
        display: block;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 1024px) and (max-width: 1200px) {}

@media (min-width: 1200.98px) and (max-width: 1359px) {}

@media (min-width: 1359.98px) and (max-width: 1440px) {}