.thankuContent {
    text-align: center;
    margin: 5rem 0;
}

.thankuContent h1 {
    font-size: 60px;
    color: var(--tertiary);
    text-transform: uppercase;
    font-family: extrabold;
    margin-bottom: 2rem;
}

.thankuContent p {
    font-size: 30px;
    color: var(--primary);
    text-transform: uppercase;
    font-family: extrabold;
    margin-top: 3rem;
}

@media (min-width: 319.98px) and (max-width: 379.98px) {
    .thankuContent {
        margin: 7rem 0 5rem 0;
    }

    .thankuContent h1 {
        font-size: 26px;
    }

    .thankuContent p {
        font-size: 16px;
    }

    .thankuContent p br {
        display: none;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .thankuContent {
        margin: 7rem 0 5rem 0;
    }

    .thankuContent h1 {
        font-size: 26px;
    }

    .thankuContent p {
        font-size: 16px;
    }

    .thankuContent p br {
        display: none;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .thankuContent {
        margin: 7rem 0 5rem 0;
    }

    .thankuContent h1 {
        font-size: 26px;
    }

    .thankuContent p {
        font-size: 16px;
    }

    .thankuContent p br {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .thankuContent {
        margin: 7rem 0 5rem 0;
    }

    .thankuContent h1 {
        font-size: 26px;
    }

    .thankuContent p {
        font-size: 16px;
    }

    .thankuContent p br {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {
    .thankuContent {
        margin: 7rem 0 5rem 0;
    }

    .thankuContent h1 {
        font-size: 26px;
    }

    .thankuContent p {
        font-size: 16px;
    }

    .thankuContent p br {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .thankuContent h1 {
        font-size: 46px;
    }

    .thankuContent p {
        font-size: 16px;
    }

    .thankuContent p br {
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .thankuContent h1 {
        font-size: 46px;
    }

}

@media (min-width: 1200.98px) and (max-width: 1359px) {
    .thankuContent h1 {
        font-size: 46px;
    }
}

@media (min-width: 1359.98px) and (max-width: 1440px) {
    .thankuContent h1 {
        font-size: 46px;
    }
}