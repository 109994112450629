.footerEnrollBg{
    background-color: var(--dark);
    padding: 2rem 0;
}
.footerEnroll ul{
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    align-items: center;
    font-family: semibold;
}
.footerEnroll ul li{
    font-size: 40px;
    color: var(--white);
}
.footerEnroll ul li button{
    background-color: var(--white);
    padding: 10px 50px;
    border-radius: 50px;
    font-size: 30px;
    font-family: semibold;
    border: none;
    outline: none;
}

.footerBg{
    background-color: var(--tertiary);
    padding: 0 0 4rem 0;
    position: relative;
}
.footerBg::after{
    position: absolute;
    content: '';
    width: 332px;
    height: 380px;
    top: 0;
    left: 0;
    background-image: url(../../../img/icons/leftPlant.webp);
}
.footerBg::before{
    position: absolute;
    content: '';
    width: 332px;
    height: 380px;
    top: 0;
    right: 0;
    background-image: url(../../../img/icons/rightPlant.webp);
}
.footerList{
    padding-left: 4rem;
}
.footerList h4{
    font-size: 20px;
    font-family: boldfont;
    margin-top: 1.5rem;
}
.footerList ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.footerList ul li{
    margin-top: 1rem;
    font-family: mediumfont;
    font-size: 18px;
}
.footerList ul li a{
    color: var(--dark);
    font-family: mediumfont;
}
.copyrightBox {
    border-top: 1px solid var(--dark);
    margin-top: 3rem;
}
.copyrightBox p{
    margin: 10px 0;
    font-family: semibold;
    color: var(--dark);
}
.copyrightBox p a{
    color: var(--dark);
    font-family: semibold;
}
.footerSocialLinks{
    text-align: center;
}
.footerSocialLinks ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footerSocialLinks ul li{
    margin: 0 10px;
}


@media (min-width: 319.98px) and (max-width: 379.98px) {
    .footerEnroll ul{
        display: inherit;
    }
    .footerEnroll ul li{
        text-align: center;
        font-size: 20px;
    }
    .footerEnroll ul li button{
        font-size: 20px;
        margin-top: 2rem;
    }
    .footerBg::before,
    .footerBg::after{
        display: none;
    }
    .footerLogo{
        text-align: center;
    }
    .footerList{
        padding: 0;
        text-align: center;
    }
    .copyrightBox p{
        text-align: center;
    }
}

@media (min-width: 380px) and (max-width: 424.98px) {
    .footerEnroll ul{
        display: inherit;
    }
    .footerEnroll ul li{
        text-align: center;
        font-size: 20px;
    }
    .footerEnroll ul li button{
        font-size: 20px;
        margin-top: 2rem;
    }
    .footerBg::before,
    .footerBg::after{
        display: none;
    }
    .footerLogo{
        text-align: center;
    }
    .footerList{
        padding: 0;
        text-align: center;
    }
    .copyrightBox p{
        text-align: center;
    }
}

@media (min-width: 425px) and (max-width: 575.98px) {
    .footerEnroll ul{
        display: inherit;
    }
    .footerEnroll ul li{
        text-align: center;
        font-size: 20px;
    }
    .footerEnroll ul li button{
        font-size: 20px;
        margin-top: 2rem;
    }
    .footerBg::before,
    .footerBg::after{
        display: none;
    }
    .footerLogo{
        text-align: center;
    }
    .footerList{
        padding: 0;
        text-align: center;
    }
    .copyrightBox p{
        text-align: center;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .footerEnroll ul{
        display: inherit;
    }
    .footerEnroll ul li{
        text-align: center;
        font-size: 20px;
    }
    .footerEnroll ul li button{
        font-size: 20px;
        margin-top: 2rem;
    }
    .footerBg::before,
    .footerBg::after{
        display: none;
    }
    .footerLogo{
        text-align: center;
    }
    .footerList{
        padding: 0;
        text-align: center;
    }
    .copyrightBox p{
        text-align: center;
    }
}

@media (min-width: 769px) and (max-width: 991.98px) {
    .footerEnroll ul{
        display: inherit;
    }
    .footerEnroll ul li{
        text-align: center;
        font-size: 20px;
    }
    .footerEnroll ul li button{
        font-size: 20px;
        margin-top: 2rem;
    }
    .footerBg::before,
    .footerBg::after{
        display: none;
    }
    .footerLogo{
        text-align: center;
    }
    .footerList{
        padding: 0;
        text-align: center;
    }
    .copyrightBox p{
        text-align: center;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 1024px) and (max-width: 1200px) {}

@media (min-width: 1200.98px) and (max-width: 1359px) {}

@media (min-width: 1359.98px) and (max-width: 1440px) {}